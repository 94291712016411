

























































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpCategoryPage extends Vue {
    public readonly name: string = 'admincp-category'
    public ListCategory: {
        id: number,
        key: string,
        name: string,
        count: number,
        active: number,
    }[] = []

    public created(): void {
        document.title = "Управление категориями - " + TITLE_DEFAULT
        this.DoUpdateCategoryList()
    }

    public DoUpdateCategoryList(): void {
        Vue.set(this, 'ListCategory', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListCategory', res.data.result)
            }
        })
    }

    public DoChangeCategoryActive(id: number, newActive: number): void {
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.change.active',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_category: id,
            active: newActive
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListCategory', res.data.result)
            }
        })
    }

    public DoRemoveCategoryItem(id: number): void {
        if (confirm('Вы действительно хотите удалить данную категорию и все ее элементы?')) {
            Vue.set(this, 'ListCategory', [])
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'category.remove',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                id_category: id
            }).then(res => {
                if (res.data.code === 0) {
                    Vue.set(this, 'ListCategory', res.data.result)
                }
            })
        }
    }
}
