

































































import {Component, Vue} from 'vue-property-decorator';
import {TITLE_DEFAULT} from "@/App/data/define";
import ServerItemInServerList from "@/App/struct/ServerItemInServerList";
import Axios from "axios";
import Session from "@/App/controller/account/session";
import GameItemInGameList from "@/App/struct/GameItemInGameList";

@Component
export default class ServerCreatePage extends Vue {
    public readonly name: string = 'server-create'

    public GameList: GameItemInGameList[] = []
    public NewCode: any = undefined
    public FormValues: {
        game_id: number,
        ip: string,
        port: number
    } = {
        game_id: 0,
        ip: "",
        port: 0
    }

    public created(): void {
        this.GameList = []
        this.NewCode = undefined
        this.FormValues = {
            game_id: 0,
            ip: "",
            port: 0
        }

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if ('code' in res.data && res.data.code == 0) {
                this.GameList = res.data.result
                this.$forceUpdate()
            } else {
                alert("Не возможно получить данные с сервера")
            }
        })

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'user.server.newcode',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if ('code' in res.data && res.data.code == 0) {
                if ('code' in res.data.result && 'hash' in res.data.result) {
                    this.NewCode = res.data.result
                    this.$forceUpdate()
                } else {
                    alert("Вместо кода получен не известный ответ")
                }
            } else {
                alert("Не возможно получить код с сервера")
            }
        })
    }

    private OnClickSubmit() {
        if (Number(this.FormValues.game_id) <= 10) {
            alert("Вы не указали игру!")
            return
        }
        if (this.FormValues.ip.length <= 0 || this.FormValues.ip.split('.').length != 4) {
            alert("IP адрес не соотвествует требованиям")
            return
        }
        if (Number(this.FormValues.port) <= 100 || Number(this.FormValues.port) >= 65535) {
            alert("Port не соотвествует требованиям")
            return
        }

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'user.server.create',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            values: {
                game_id: this.FormValues.game_id,
                ip: this.FormValues.ip,
                port: this.FormValues.port,
                code: this.NewCode.code,
                hash: this.NewCode.hash,
            }
        }).then(res => {
            if ('code' in res.data && res.data.code == 0) {
                this.$root.$router.push('/user/servers')
            } else {
                alert("Не возможно прикрепить сервер")
            }
        })
    }

    private destroyed(): void {

    }

    public mounted(): void {
        document.title = "Прикрепление сервера - " + TITLE_DEFAULT
        console.log('server-create - started')
    }
}
