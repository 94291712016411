










import '../asset/css/common-enther-template.less'

import Url from '../controller/net/url'

import {Component, Vue} from 'vue-property-decorator';

@Component
export default class EntherTemplate extends Vue {
    public readonly name: string = 'enther-template'

    private created() {
        if (Url.ContainsUrl('enther', 0) === false) {
            Url.SetUrl('/')
        }
    }
}
