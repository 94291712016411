
















import '../asset/css/common-enther-template.less'

import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";

@Component
export default class AdmincpMenuComponent extends Vue {
    public readonly name: string = 'admincp-menu-component'

    private UpdateServers(): void {
        if (confirm('Старые данные будут удалены, вы действительно хотите это сделать?')) {
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'server.parsecdn',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
            }).then(res => {
                alert('Готово!')
            })
        }
    }
}
