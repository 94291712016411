



























































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import Url from "@/App/controller/net/url";
import {TITLE_DEFAULT} from "@/App/data/define";
import AdmincpMenu from "@/App/component/admincp-menu.vue";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpServerEditPage extends Vue {
    public readonly name: string = 'admincp-server-edit'
    private HasDataSend: boolean = false
    private HasDataLoading: boolean = true
    private FormValues: {
        id: number,
        id_game: number,
        name: string,
        ip: string,
        port: string,
        gamedir: string,
        web: string,
        shop: string,
        anticheat: string,
    } = {
        id: Number(this.$root.$route.params.id_server),
        id_game: ('id_game' in this.$root.$route.params ? Number(this.$root.$route.params.id_game) : 0),
        name: '',
        ip: '',
        port: '',
        gamedir: '',
        web: '',
        shop: '',
        anticheat: 'NoSecure',
    }
    private ListGame: {
        id: number,
        id_category: number,
        category_name: string,
        name: string
    }[] = []

    private created(): void {
        document.title = "Изменение сервера - " + TITLE_DEFAULT
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListGame', res.data.result)
            }
        })
        this.DoLoadData()
    }

    private DoLoadData() {
        this.HasDataLoading = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.item',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: this.FormValues.id,
        }).then(res => {
            if (res.data.code === 0) {
                this.FormValues.id_game = res.data.result.id_game
                this.FormValues.name = res.data.result.name
                this.FormValues.ip = res.data.result.ip
                this.FormValues.port = res.data.result.port
                this.FormValues.gamedir = res.data.result.gamedir
                this.FormValues.web = res.data.result.web
                this.FormValues.shop = res.data.result.shop
                this.FormValues.anticheat = res.data.result.anticheat
            } else {
                Url.SetUrl('/admincp/server/')
            }
        }).finally(() => {
            this.HasDataLoading = false
        })
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.edit',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: this.FormValues.id,
            id_game: this.FormValues.id_game,
            name: this.FormValues.name,
            ip: this.FormValues.ip,
            port: this.FormValues.port,
            gamedir: this.FormValues.gamedir,
            web: this.FormValues.web,
            shop: this.FormValues.shop,
            anticheat: this.FormValues.anticheat,
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/server/')
        })
    }
}
