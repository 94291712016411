import Vue from 'vue'
import App from './App/App.vue'

import RouterData from './App/data/router'

import Session from './App/controller/account/session'
import Language from './App/controller/data/language'
import AppController from './App/controller/web/app'


Vue.config.productionTip = false

new Vue({
    router: RouterData,
    data: {
        Session: Session
    },
    created(): void {
        AppController.DoInit(this)
        Language.DoInit()
        Session.DoInit()
    },
    render: h => h(App)
}).$mount('#app')
