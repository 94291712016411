import Vue from 'vue'
import Router from 'vue-router'

import Error404Page from '../page/error/error-404-page.vue'

import EntherLoginPage from '../page/enther/login.vue'
import EntherRegPage from '../page/enther/registration.vue'

import DashboardPage from '../page/panel/dashboard.vue'
import UserLogoutPage from "@/App/page/panel/user/logout.vue";
import AdmincpNewsPage from "@/App/page/panel/admincp/news.vue";
import AdmincpNewsCreatePage from "@/App/page/panel/admincp/news-create.vue";
import AdmincpNewsEditPage from "@/App/page/panel/admincp/news-edit.vue";
import AdmincpPagePage from "@/App/page/panel/admincp/page.vue";
import AdmincpPageCreatePage from "@/App/page/panel/admincp/page-create.vue";
import AdmincpPageEditPage from "@/App/page/panel/admincp/page-edit.vue";
import NewsItemPage from "@/App/page/panel/news-item.vue";
import PageItemPage from "@/App/page/panel/page-item.vue";
import AdmincpUserPage from "@/App/page/panel/admincp/user.vue";
import AdmincpCategoryPage from "@/App/page/panel/admincp/category.vue";
import AdmincpCategoryCreatePage from "@/App/page/panel/admincp/category-create.vue";
import AdmincpCategoryEditPage from "@/App/page/panel/admincp/category-edit.vue";
import AdmincpGamePage from "@/App/page/panel/admincp/game.vue";
import AdmincpGameCreatePage from "@/App/page/panel/admincp/game-create.vue";
import AdmincpGameEditPage from "@/App/page/panel/admincp/game-edit.vue";
import AdmincpServerPage from "@/App/page/panel/admincp/server.vue";
import AdmincpServerCreatePage from "@/App/page/panel/admincp/server.create.vue";
import AdmincpServerEditPage from "@/App/page/panel/admincp/server.edit.vue";
import ServersPage from "@/App/page/panel/servers.vue";
import ServerListPage from "@/App/page/panel/user/server-list.vue";
import ServerCreatePage from "@/App/page/panel/user/server-create.vue";
import ServerEditPage from "@/App/page/panel/user/server-edit.vue";

Vue.use(Router)

const routerExample: any = new Router({
    mode: 'history',
    routes: [
        //region /Enther/*
        {
            path: '/enther/login',
            name: 'EntherLoginPage',
            component: EntherLoginPage
        },
        {
            path: '/enther/login/:email',
            name: 'EntherLoginPageFromEmail',
            component: EntherLoginPage
        },
        {
            path: '/enther/registration',
            name: 'EntherRegPage',
            component: EntherRegPage
        },
        //endregion
        {
            path: '/admincp/news',
            name: 'AdmincpNewsPage',
            component: AdmincpNewsPage
        },
        {
            path: '/admincp/news-create',
            name: 'AdmincpNewsCreatePage',
            component: AdmincpNewsCreatePage
        },
        {
            path: '/admincp/news-edit/:id_news',
            name: 'AdmincpNewsEditPage',
            component: AdmincpNewsEditPage
        },

        {
            path: '/admincp/page',
            name: 'AdmincpPagePage',
            component: AdmincpPagePage
        },
        {
            path: '/admincp/page-create',
            name: 'AdmincpPageCreatePage',
            component: AdmincpPageCreatePage
        },
        {
            path: '/admincp/page-edit/:id_page',
            name: 'AdmincpPageEditPage',
            component: AdmincpPageEditPage
        },

        {
            path: '/admincp/user',
            name: 'AdmincpUserPage',
            component: AdmincpUserPage
        },

        {
            path: '/admincp/category',
            name: 'AdmincpCategoryPage',
            component: AdmincpCategoryPage
        },
        {
            path: '/admincp/category-create',
            name: 'AdmincpCategoryCreatePage',
            component: AdmincpCategoryCreatePage
        },
        {
            path: '/admincp/category-edit/:id_category',
            name: 'AdmincpCategoryEditPage',
            component: AdmincpCategoryEditPage
        },

        {
            path: '/admincp/game',
            name: 'AdmincpGamePage',
            component: AdmincpGamePage
        },
        {
            path: '/admincp/game-create',
            name: 'AdmincpGameCreatePage',
            component: AdmincpGameCreatePage
        },
        {
            path: '/admincp/game-edit/:id_game',
            name: 'AdmincpGameEditPage',
            component: AdmincpGameEditPage
        },

        {
            path: '/admincp/server',
            name: 'AdmincpServerPage',
            component: AdmincpServerPage
        },
        {
            path: '/admincp/server-create',
            name: 'AdmincpServerCreatePage',
            component: AdmincpServerCreatePage
        },
        {
            path: '/admincp/server-create/:id_game',
            name: 'AdmincpServerCreatePageFromGame',
            component: AdmincpServerCreatePage
        },
        {
            path: '/admincp/server-edit/:id_server',
            name: 'AdmincpServerEditPage',
            component: AdmincpServerEditPage
        },
        {
            path: '/user/logout',
            name: 'UserLogoutPage',
            component: UserLogoutPage
        },
        {
            path: '/user/servers',
            name: 'ServerListPage',
            component: ServerListPage
        },
        {
            path: '/user/server-create',
            name: 'ServerCreatePage',
            component: ServerCreatePage
        },
        {
            path: '/user/servers/:id/edit',
            name: 'ServerEditPage',
            component: ServerEditPage
        },
        {
            path: '/',
            name: 'DashboardPage',
            component: DashboardPage
        },
        {
            path: '/news/:anchor',
            name: 'NewsItemPage',
            component: NewsItemPage
        },
        {
            path: '/page/:anchor',
            name: 'PageItemPage',
            component: PageItemPage
        },
        {
            path: '/servers',
            name: 'ServersPage',
            component: ServersPage
        },
        {
            path: '*',
            name: 'Error404Page',
            component: Error404Page
        }
    ]
})

export default routerExample