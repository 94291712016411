




































































import {Component, Vue} from 'vue-property-decorator';
import {TITLE_DEFAULT} from "@/App/data/define";
import ServerItemInServerList from "@/App/struct/ServerItemInServerList";
import Axios from "axios";
import Session from "@/App/controller/account/session";

@Component
export default class ServerListPage extends Vue {
    public readonly name: string = 'server-list'

    public ServerList: ServerItemInServerList[] | undefined
    private timerExample: any = undefined

    public created(): void {
        this.ServerList = undefined
        this.timerExample = setInterval(() => this.TimerTickUpdate(), 60000)
        this.TimerTickUpdate()
    }

    private destroyed(): void {
        clearInterval(this.timerExample)
        this.timerExample = undefined
    }

    private GetOnlinePlayersFromServer(server: ServerItemInServerList): number {
        let online = 0;
        const splittedTags: string[] = server.gametype.split(',')
        for (const tag of splittedTags) {
            const tagStart: string = tag.substr(0, 2);
            switch (tagStart) {
                case 'cp':
                case 'ai':
                    online = online + Number(tag.substr(2))
                    break
                case 'fl':
                    return Number(tag.substr(2))
            }
        }
        if (online == 0 && online != Number(server.players)) {
            return Number(server.players)
        }
        return online
    }

    private TimerTickUpdate(): void {
        if (this.timerExample != undefined) {
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'user.server.list',
                //@ts-ignore
                session: Session.CurrentSession.SessionID
            }).then(res => {
                this.ServerList = undefined
                if ('code' in res.data && res.data.code == 0) {
                    this.ServerList = res.data.result;
                }
                this.$forceUpdate()
            })
        }
    }

    private OnClickChangeActive(server: ServerItemInServerList): void {
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'user.server.change.active',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: server.id
        }).then(res => {
            if ('active' in res.data) {
                server.active = res.data.active
            }
            this.$forceUpdate()
        })
    }

    private GetTableRowVipClass(server: ServerItemInServerList): string {
        return (this.HasPremiumFromServerItem(server) ? 'premium' : (this.HasVipFromServerItem(server) ? "vip" : ""))
    }

    private HasPremiumFromServerItem(server: ServerItemInServerList): boolean {
        const currentTime: Date = new Date();
        return (Number(server.premium) === 1 && new Date(server.vipend) > currentTime)
    }

    private HasVipFromServerItem(server: ServerItemInServerList): boolean {
        const currentTime: Date = new Date();
        return (Number(server.premium) === 0 && new Date(server.vipend) > currentTime)
    }

    private GetVipOrPremiumText(value: ServerItemInServerList): string {
        const hasVip: boolean = this.HasVipFromServerItem(value)
        const hasPremium: boolean = this.HasPremiumFromServerItem(value)

        if (hasVip == true || hasPremium == true) {
            const currentDate = new Date();
            const endDate = new Date(value.vipend)
            var diffMS = endDate.getTime() - currentDate.getTime();
            var diffS = diffMS / 1000;
            var diffM = diffS / 60;
            var diffH = diffM / 60;
            var diffD = diffH / 24;

            let finishLine: string = (hasVip ? "Вип: " : "Премиум: ")
            finishLine = finishLine + "~" + Math.floor(diffM) + " мин"
            return finishLine;
        } else {
            return "Нет Привелегий"
        }
    }

    public mounted(): void {
        document.title = "Список моих серверов - " + TITLE_DEFAULT
        console.log('server-list - started')
    }
}
