



















































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpPagePage extends Vue {
    public readonly name: string = 'admincp-page'
    public ListPage: {
        id: number,
        anchor: string,
        title: string,
        small: string,
        date: string,
        login: string
    }[] = []

    public created(): void {
        document.title = "Управление страницами - "+ TITLE_DEFAULT
        this.DoUpdatePageList()
    }

    public DoUpdatePageList(): void {
        Vue.set(this, 'ListPage', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'page.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListPage', res.data.result)
            }
        })
    }

    public DoRemovePageItem(id: number): void {
        if (confirm('Вы действительно хотите удалить данную страницу?')) {
            Vue.set(this, 'ListPage', [])
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'page.remove',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                id_page: id
            }).then(res => {
                if (res.data.code === 0) {
                    Vue.set(this, 'ListPage', res.data.result)
                }
            })
        }
    }
}
