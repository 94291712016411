

















import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component
export default class DashboardPage extends Vue {
    public readonly name: string = 'dashboard'

    public ListNews: {
        id: number,
        anchor: string,
        title: string,
        small: string,
        date: string,
        login: string
    }[] = []

    public created(): void {
        document.title = TITLE_DEFAULT
        this.DoUpdateNewsList()
    }

    public DoUpdateNewsList(): void {
        Vue.set(this, 'ListNews', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'news.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListNews', res.data.result)
            }
        })
    }
}
