























































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpNewsPage extends Vue {
    public readonly name: string = 'admincp-news'
    public ListNews: {
        id: number,
        anchor: string,
        title: string,
        small: string,
        date: string,
        login: string
    }[] = []

    public created(): void {
        document.title = "Управление публикациями - " + TITLE_DEFAULT
        this.DoUpdateNewsList()
    }

    public DoUpdateNewsList(): void {
        Vue.set(this, 'ListNews', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'news.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListNews', res.data.result)
            }
        })
    }

    public DoRemoveNewsItem(id: number): void {
        if (confirm('Вы действительно хотите удалить данную публикацию?')) {
            Vue.set(this, 'ListNews', [])
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'news.remove',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                id_news: id
            }).then(res => {
                if (res.data.code === 0) {
                    Vue.set(this, 'ListNews', res.data.result)
                }
            })
        }
    }
}
