







import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css"

import "./asset/css/common-reset.css"
import "./asset/css/common-template.less"
import "./asset/css/common-theme.less"

import {Component, Vue} from "vue-property-decorator";

import EntherTemplate from "./template/enther-template.vue"
import PanelTemplate from "./template/panel-template.vue"
import Session from "@/App/controller/account/session";

@Component({
    components: {PanelTemplate, EntherTemplate}
})
export default class App extends Vue {
    public readonly name: string = "App"

    private HasSession(): boolean {
        return Session.HasSession
    }
}
