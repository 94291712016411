































































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpGamePage extends Vue {
    public readonly name: string = 'admincp-game'
    public ListGame: {
        id: number,
        id_category: number,
        category_name: string,
        key: string,
        name: string,
        icon: string,
        background: string,
        updateurl: string,
        execute: string,
        buttonname: string,
        versionfile: string,
        versionhash: string,
        nowversion: string,
        count: number,
        needsteam: number,
        default: number,
        active: number,
    }[] = []

    public created(): void {
        document.title = "Управление играми - " + TITLE_DEFAULT
        this.DoUpdateGameList()
    }

    public DoUpdateGameList(): void {
        Vue.set(this, 'ListGame', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListGame', res.data.result)
            }
        })
    }

    public DoChangeGameActive(id: number, newActive: number): void {
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.change.active',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_game: id,
            active: newActive
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListGame', res.data.result)
            }
        })
    }

    public DoRemoveGameItem(id: number): void {
        if (confirm('Вы действительно хотите удалить данную игру и все ее сервера?')) {
            Vue.set(this, 'ListGame', [])
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'game.remove',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                id_game: id
            }).then(res => {
                if (res.data.code === 0) {
                    Vue.set(this, 'ListGame', res.data.result)
                }
            })
        }
    }
}
