import * as Defines from '../../data/define'

class Storage {
    public static GetValue(key: string): object | string | undefined {
        if (typeof key !== 'string' || key.length === 0 || Storage.ContainsKey(key) !== true) {
            return undefined
        }
        const fullKey: string = Defines.STORAGE_PREFIX + key
        let val: string | null = localStorage.getItem(fullKey)
        if (val !== null) {
            const typeValue: string = val.substr(0, 1)
            val = val.substr(1)
            if (typeValue === '1') {
                try {
                    return JSON.parse(val)
                } catch (e) {
                    return undefined
                }
            }
        } else {
            return undefined
        }
        return val
    }

    public static SetValue(key: string, value: any): void {
        if (typeof key !== 'string' || key.length === 0) {
            return
        }
        if (value === undefined || value === null) {
            Storage.RemoveKey(key)
            return
        }
        let endValue: string = ''
        if (typeof value === 'object') {
            try {
                endValue = '1' + JSON.stringify(value)
            } catch (e) {
                endValue = '1{}'
            }
        } else {
            endValue = '0' + value
        }
        const fullKey: string = Defines.STORAGE_PREFIX + key
        localStorage.setItem(fullKey, endValue)
    }

    public static ContainsKey(key: string): boolean {
        if (typeof key !== 'string' || key.length === 0) {
            return false
        }
        const fullKey: string = Defines.STORAGE_PREFIX + key
        const val: any = localStorage.getItem(fullKey)
        return (val !== null)
    }

    public static RemoveKey(key: string): void {
        if (typeof key !== 'string' || key.length === 0) {
            return
        }
        if (Storage.ContainsKey(key) === true) {
            const fullKey: string = Defines.STORAGE_PREFIX + key
            localStorage.removeItem(fullKey)
        }
    }
}

export default Storage
