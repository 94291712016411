




























































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpUserPage extends Vue {
    public readonly name: string = 'admincp-nuser'
    public ListUser: {
        id: number,
        login: string,
        email: string,
        balance: number,
        access: number,
        regdate: string
    }[] = []

    public created(): void {
        document.title = "Управление пользователями - " + TITLE_DEFAULT
        this.DoUpdateUserList()
    }

    public DoUpdateUserList(): void {
        Vue.set(this, 'ListUser', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'user.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListUser', res.data.result)
            }
        })
    }
}
