










































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import SessionData from "@/App/model/account/SessionData";
import Url from "@/App/controller/net/url";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";
@Component({
    components: {AdmincpMenu}
})
export default class AdmincpNewsEditPage extends Vue {
    public readonly name: string = 'admincp-news-edit'
    public Editor: any = undefined
    private HasDataSend: boolean = false
    private HasDataLoading: boolean = true
    private FormValues: {
        id: number
        title: string,
    } = {
        id: Number(this.$root.$route.params.id_news),
        title: ''
    }

    private mounted(): void {
        document.title = "Управление публикацией - " + TITLE_DEFAULT
        if (isNaN(this.FormValues.id) === true) {
            Url.SetUrl('/admincp/news/')
            return
        }

        //@ts-ignore
        this.Editor = new Quill('#editor', {
            modules: {
                syntax: true
            },
            theme: 'snow'
        });
        this.DoLoadData()
    }

    private DoLoadData() {
        this.HasDataLoading = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'news.item',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_news: this.FormValues.id
        }).then(res => {
            if (res.data.code === 0) {
                this.Editor.root.innerHTML = res.data.result.full
                this.FormValues.title = res.data.result.title
            } else {
                Url.SetUrl('/admincp/news/')
            }
        }).finally(() => {
            this.HasDataLoading = false
        })
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'news.edit',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_news: this.FormValues.id,
            title: this.FormValues.title,
            text: this.Editor.root.innerHTML
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/news/')
        })
    }
}
