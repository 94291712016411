








import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component
export default class PageItemPage extends Vue {
    public readonly name: string = 'page-item-page'

    public HasLoadedItem: boolean = false
    public PageItem: {
        id: number,
        anchor: string,
        title: string,
        small: string,
        full: string,
        date: string,
        login: string
    } | undefined

    public created(): void {
        document.title = "... - " + TITLE_DEFAULT
        this.DoUpdatePageItem()
    }

    public DoUpdatePageItem(): void {
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'page.item',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            anchor: this.$root.$route.params.anchor
        }).then(res => {
            if (res.data.code === 0) {
                this.$nextTick(() => {
                    this.PageItem = res.data.result
                    this.HasLoadedItem = true
                    //@ts-ignore
                    document.title = this.PageItem.title + " - " + TITLE_DEFAULT
                })
            }
        })
    }
}
