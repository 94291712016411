


































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import Url from "@/App/controller/net/url";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpCategoryEditPage extends Vue {
    public readonly name: string = 'admincp-category-edit'
    private HasDataSend: boolean = false
    private HasDataLoading: boolean = true
    private FormValues: {
        id: number
        name: string,
        key: string
    } = {
        id: Number(this.$root.$route.params.id_category),
        name: '',
        key: '',
    }

    private mounted(): void {
        document.title = "Управление категорией - " + TITLE_DEFAULT
        if (isNaN(this.FormValues.id) === true) {
            Url.SetUrl('/admincp/category/')
            return
        }
        this.DoLoadData()
    }

    private DoLoadData() {
        this.HasDataLoading = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.item',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_category: this.FormValues.id
        }).then(res => {
            if (res.data.code === 0) {
                this.FormValues.key = res.data.result.key
                this.FormValues.name = res.data.result.name
            } else {
                Url.SetUrl('/admincp/category/')
            }
        }).finally(() => {
            this.HasDataLoading = false
        })
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.edit',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_category: this.FormValues.id,
            name: this.FormValues.name,
            key: this.FormValues.key
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/category/')
        })
    }
}
