import Session from "@/App/controller/account/session";
import SessionData from "@/App/model/account/SessionData";

class AccountData {
    public Email: string | undefined
    public Login: string | undefined
    public ID: number | undefined
    public Balance: number = 0
    public Access: number = 0

    public HasAdmin(): boolean {
        return this.Access > 1
    }

    public constructor(obj: any) {
        if (typeof obj !== "object") {
            return
        }
        if ('email' in obj) {
            this.Email = obj.email
        }
        if ('login' in obj) {
            this.Login = obj.login
        }
        if ('id' in obj) {
            this.ID = obj.id
        }
        if ('balance' in obj) {
            this.Balance = obj.balance
        }
        if ('access' in obj) {
            this.Access = obj.access
        }
    }

    public Parse(): object {
        return {
            email: this.Email,
            login: this.Login,
            id: this.ID,
            balance: this.Balance,
            access: this.Access,
            sessionID: (<SessionData>Session.CurrentSession).SessionID
        }
    }
}

export default AccountData