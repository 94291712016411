import Vue from 'vue'

import Axios from 'axios'

import Url from '../net/url'

import Storage from '../data/storage'
import * as Defines from '../../data/define'

import SessionData from '../../model/account/SessionData'
import AccountData from "@/App/model/account/AccountData";
import AppController from '@/App/controller/web/app'

class Session {
    public static readonly StartPath: string = location.href
    public static CurrentSession: SessionData | undefined = undefined
    public static HasSession: boolean = false

    public static DoInit(): void {
        const lastSession: any = Storage.GetValue(Defines.STORAGE_KEY_LASTSESSION)
        Session.OnIncomingCurrentSession(SessionData.GetSessionDateFromObject(lastSession))
        if (this.HasSession === true) {
            Axios.post('//kosiaks.alkad.org/api/index.php', {session: (<SessionData>this.CurrentSession).SessionID, method: 'user.current.session'}).then(res => {
                if (res.data.code === 0) {
                    this.OnIncomingCurrentSession(SessionData.GetSessionDateFromObject(res.data.result))
                } else {
                    this.OnIncomingCurrentSession(undefined)
                }
            })
        }
    }

    public static OnIncomingCurrentSession(sessionData: SessionData | undefined): void {
        try {
            // Записываем данные в Session.CurrentSession, определяем наличие сесии и записываем в Storage
            Vue.set(Session, 'CurrentSession', sessionData)
            Vue.set(Session, 'HasSession', ((sessionData === undefined) ? false : true))
            Storage.SetValue(Defines.STORAGE_KEY_LASTSESSION, (sessionData === undefined ? undefined : (<AccountData>(<SessionData>sessionData).Account).Parse()))

            // Если нет сесии и после первого '/' не enther, то переходим на страницу '/enther/login'
            if (Session.HasSession === false && Url.ContainsUrl('enther', 0) === false) {
                Url.SetUrl('/enther/login')
            }
            AppController.ForceUpdate()
        } catch (e) {
            console.error(e)
        }
    }
}

export default Session
