import Vue from 'vue'

import Storage from './storage'
import AppController from '../web/app'

import LanguageData from '../../data/language'
import * as Defines from '../../data/define'

class Language {
    public CurrentLanguage: string = ''
    private CurrentText: string = ''

    public DoInit(): void {
        let currentLang: string | object | undefined = Storage.GetValue(Defines.LANGUAGE_STORAGE_KEY)
        if (typeof currentLang !== 'string') {
            currentLang = Defines.LANGUAGE_DEFAULT_VALUE
        }
        if (typeof currentLang === 'string') {
            this.SetLanguage(currentLang)
        }
    }

    public SetLanguage(value: string) {
        if (value !== this.CurrentLanguage && value in LanguageData) {
            Storage.SetValue(Defines.LANGUAGE_STORAGE_KEY, value)
            Vue.set(this, 'CurrentLanguage', value)
            AppController.ForceUpdate()
        }
    }

    public SelectKey(key: string): Language {
        this.CurrentText = ''
        if (this.CurrentLanguage in LanguageData && key in LanguageData[this.CurrentLanguage]) {
            this.CurrentText = LanguageData[this.CurrentLanguage][key]
        }
        return this
    }

    public ReplaceSubKey(subKey: string, text: any): Language {
        this.CurrentText = this.CurrentText.replace('{' + subKey + '}', text)
        return this
    }

    public GetText(): string {
        return this.CurrentText
    }
}

export default new Language