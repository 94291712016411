const Language: any = {
    "ru": {
        "enther_left_wrapper_h1": "Присоеденяйтесь к GameStores",
        "enther_left_wrapper_h2": "Добро пожаловать в GameStores. Изготовьте ваш донат магазин в 1 клик и продавайте ваши игровые предмметы и услуги точно также в 1 клик на платформе GameStores.",
        //region /enther/registration
        "enther_right_registration_h2": "Создание Личного Кабинета",
        "enther_right_registration_form_email": "Email",
        "enther_right_registration_form_login": "Логин",
        "enther_right_registration_form_password": "Пароль",
        "enther_right_registration_form_password2": "Еще раз пароль",
        "enther_right_registration_form_back": "Назад",
        "enther_right_registration_form_submit": "Зарегистрироваться",
        "enther_right_registration_form_forgot_text": "Забыли пароль от Личного Кабинета?",
        "enther_right_registration_form_forgot_button": "Восстановить доступ",
        //endregion
        //region /enther/login
        "enther_right_login_h2": "Вход в Личный Кабинет",
        "enther_right_login_form_email": "Email",
        "enther_right_login_form_password": "Пароль",
        "enther_right_login_form_hasforgot": "Забыли пароль?",
        "enther_right_login_form_remember": "Запомнить меня",
        "enther_right_login_form_submit": "Войти",
        "enther_right_login_form_needreg": "У вас нет личного кабинета?",
        "enther_right_login_form_needreg_button": "Зарегестрируйтесь",
        //endregion
        //region /enther/forgot-password
        "enther_right_forgot_h2": "Восстановление доступа",
        "enther_right_forgot_form_email": "Email",
        "enther_right_forgot_form_back": "Вспомнили пароль?",
        "enther_right_forgot_form_submit": "Отправить письмо с инструкцией",
        "enther_right_forgot_form_hasreg": "У вас нет личного кабинета?",
        "enther_right_forgot_form_hasreg_button": "Зарегестрируйтесь",
        //endregion
    },
    "en": {
        "enther_left_wrapper_h1": "Join our GameStores",
        "enther_left_wrapper_h2": "Welcome to GameStores. Make your donat shop in 1 click and sell your gaming items and services in exactly the same 1 click on the GameStores platform.",
        //region /enther/registration
        "enther_right_registration_h2": "Create a Personal Account",
        "enther_right_registration_form_email": "Email",
        "enther_right_registration_form_login": "Login",
        "enther_right_registration_form_password": "Password",
        "enther_right_registration_form_password2": "Confirm Password",
        "enther_right_registration_form_back": "Back",
        "enther_right_registration_form_submit": "Sign up",
        "enther_right_registration_form_forgot_text": "Forgot your password from Personal Account?",
        "enther_right_registration_form_forgot_button": "Restore access",
        //endregion
        //region /enther/login
        "enther_right_login_h2": "Login to Personal Account",
        "enther_right_login_form_email": "Email",
        "enther_right_login_form_password": "Password",
        "enther_right_login_form_hasforgot": "Forgot your password?",
        "enther_right_login_form_remember": "Remember me",
        "enther_right_login_form_submit": "Sign in",
        "enther_right_login_form_needreg": "You do not have a personal account?",
        "enther_right_login_form_needreg_button": "Sign up",
        //endregion
        //region /enther/forgot-password
        "enther_right_forgot_h2": "Access Recovery",
        "enther_right_forgot_form_email": "Email",
        "enther_right_forgot_form_back": "Remember your password?",
        "enther_right_forgot_form_submit": "Send an instruction letter",
        "enther_right_forgot_form_hasreg": "You do not have a personal account?",
        "enther_right_forgot_form_hasreg_button": "Sign up",
        //endregion
    }
}

export default Language