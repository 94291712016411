





























import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import SessionData from "@/App/model/account/SessionData";
import Url from "@/App/controller/net/url";
import {TITLE_DEFAULT} from "@/App/data/define";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
@Component({
    components: {AdmincpMenu}
})
export default class AdmincpCategoryCreatePage extends Vue {
    public readonly name: string = 'admincp-category-create'
    private HasDataSend: boolean = false
    private FormValues: {
        name: string
        key: string
    } = {
        name: '',
        key: ''
    }

    private created(): void {
        document.title = "Создание категории - " + TITLE_DEFAULT
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.create',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            name: this.FormValues.name,
            key: this.FormValues.key
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/category/')
        })
    }
}
