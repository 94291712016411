import AccountData from "../account/AccountData";


class SessionData {

    public SessionID: string | undefined = undefined
    public Account: AccountData | undefined = undefined

    private constructor(obj: any) {
        this.SessionID = obj.sessionID
    }

    public static GetSessionDateFromObject(obj: any): SessionData | undefined {
        if (obj !== undefined && 'sessionID' in obj && typeof obj.sessionID === 'string' && obj.sessionID.length === 32) {
            const session = new SessionData(obj)
            if (session !== undefined) {
                session.Account = new AccountData(obj)
            }
            return session
        }
        return undefined
    }
}

export default SessionData