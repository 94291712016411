
























































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import "../../asset/css/common-servers-page.less"
import {TITLE_DEFAULT} from "@/App/data/define";
import {Dictionary} from "vue-router/types/router";
import ServerItemFromGame from "@/App/struct/ServerItemFromGame";
import GameItemFromCategory from "@/App/struct/GameItemFromCategory";
import CategoryItem from "@/App/struct/CategoryItem";
import GamesPacket from "@/App/struct/GamesPacket";
import Session from "@/App/controller/account/session";
import Url from "@/App/controller/net/url";

@Component({
    filters: {
        onlineFromTags(value: any): string {
            let result: string = value.players + "/" + value.maxplayers
            if (value.gametype.indexOf('cp') !== -1 && value.gametype.indexOf('mp') !== -1) {
                let players: string = '0'
                let maxplayers: string = '0'
                let splited: string[] = value.gametype.split(',')
                for (let item of splited) {
                    if (item.substr(0, 2) === 'cp') {
                        players = String(Number(item.substr(2)) + Number(players))
                    } else if (item.substr(0, 2) === 'mp') {
                        maxplayers = item.substr(2)
                    } else if (item.substr(0, 2) === 'ai') {
                        players = String(Number(item.substr(2)) + Number(players))
                    }
                }
                result = players + " / " + maxplayers
            }
            return result
        },
        hasNoSteam(value: any): string {
            return (value.gametype.indexOf(',ai') !== -1 ? '<font color="red">NoSteam</font>' : '<font color="green">Steam</font>')
        }
    }
})
export default class ServersPage extends Vue {
    public readonly name: string = 'servers'

    public MyVoteID: number = 0
    public ListServers?: GamesPacket
    public SelectedGame?: GameItemFromCategory

    public created(): void {
        document.title = TITLE_DEFAULT;
        this.ListServers = undefined
        this.SelectedGame = undefined

        this.DoUpdateServersList()
        setInterval(this.DoUpdateServersList, 60000)
    }

    public OnSelectedGame(selectedGame: GameItemFromCategory): void {
        this.SelectedGame = selectedGame
        this.$forceUpdate()
    }

    public GetArrayServersFromDictinaty(servers: Dictionary<ServerItemFromGame>): ServerItemFromGame[] {
        let result: ServerItemFromGame[] = []
        let currentTime: Date = new Date();
        for (let key in servers) {
            servers[key].hasPremium = (Number(servers[key].premium) === 1 && new Date(servers[key].vipend) > currentTime)
            servers[key].hasVip = (Number(servers[key].premium) === 0 && new Date(servers[key].vipend) > currentTime)
            result.push(servers[key])
        }
        result = result.sort(function (a, b) {
            if (a.hasPremium === true) {
                return -1
            }
            if (b.hasPremium === true) {
                return 1
            }
            if (a.hasVip === true && b.hasVip === false) {
                return -1
            }
            if (b.hasVip === true && a.hasVip === false) {
                return 1
            }
            if (b.hasVip === true && a.hasVip === true) {
                return (new Date(a.vipend) > new Date(b.vipend) ? -1 : 1)
            }
            if (Number(a.vote) > Number(b.vote)) {
                return -1
            }
            if (Number(a.vote) < Number(b.vote)) {
                return 1
            }
            return 0
        });
        return result
    }

    public DoUpdateServersList(): void {
        let self: ServersPage = this
        Vue.set(self, 'ListServers', undefined)
        Axios.get('//kosiaks.alkad.org/sapi/games').then(res => {
            self.ListServers = res.data
            if (self.SelectedGame == undefined && self.ListServers != undefined) {
                self.SelectedGame = self.ListServers.categories[self.ListServers.default.category].games[self.ListServers.default.game]
            }
            self.$forceUpdate()
        })
    }
}
