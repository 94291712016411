





































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import SessionData from "@/App/model/account/SessionData";
import Url from "@/App/controller/net/url";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpPageCreatePage extends Vue {
    public readonly name: string = 'admincp-page-create'
    public Editor: any = undefined
    private HasDataSend: boolean = false
    private FormValues: {
        title: string
    } = {
        title: ''
    }

    private mounted(): void {
        document.title = "Создание страницы - " + TITLE_DEFAULT
        console.log('admincp-page-create - started')
        //@ts-ignore
        this.Editor = new Quill('#editor', {
            modules: {
                syntax: true
            },
            theme: 'snow'
        });
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'page.create',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            title: this.FormValues.title,
            text: this.Editor.root.innerHTML
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/page/')
        })
    }
}
