








































import '../asset/css/common-enther-template.less'

import Session from "@/App/controller/account/session";

import {Component, Vue} from 'vue-property-decorator';

@Component
export default class PanelNavbarComponent extends Vue {
    public readonly name: string = 'panel-navbar-component'
    public readonly SessionController = Session
}
