



































import {Component, Vue} from 'vue-property-decorator'
import Axios from 'axios'
import AccountController from '../../controller/account/session'
import SessionData from '../../model/account/SessionData'
import Url from '../../controller/net/url'
import {TITLE_DEFAULT} from "@/App/data/define";

@Component
export default class EntherLoginPage extends Vue {
    public readonly name: string = 'login'
    public readonly FormValues: { email: string, password: string } = {
        email: '',
        password: ''
    }
    public readonly FormState: { HasSendingData: boolean } = {
        HasSendingData: false
    }

    private created() {
        document.title = "Вход - " + TITLE_DEFAULT
        if ('email' in this.$root.$route.params) {
            this.FormValues.email = this.$root.$route.params.email
        }
    }

    private OnSubmit(): void {
        this.FormState.HasSendingData = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {method: 'enther.login', email: this.FormValues.email, password: this.FormValues.password}).then(res => {
            if (res.data.code === 0) {
                AccountController.OnIncomingCurrentSession(SessionData.GetSessionDateFromObject(res.data.result))
            } else {
                alert('Введенные данные не верны!')
            }
        }).catch(reason => {
            alert('Произошла ошибка, попробуйте еще раз или обратитесь в поддержку!')
        }).finally(() => {
            this.FormState.HasSendingData = false
        })
    }

    //<editor-fold defaultstate="collapsed" desc="Validate Class">
    get Class_VariableEmail(): string {
        if (this.FormValues.email.length > 0) {
            return ' ' + (this.Validate_VariableEmail === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    get Class_VariablePassword(): string {
        if (this.FormValues.password.length > 0) {
            return ' ' + (this.Validate_VariablePassword === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    //</editor-fold>

    //<editor-fold defaultstate="collapsed" desc="Validate Properties">
    get Validate_CanSubmit(): boolean {
        return (this.FormState.HasSendingData === false && this.Validate_CanReadyForm == true)
    }

    get Validate_CanReadyForm(): boolean {
        return (this.Validate_VariableEmail === true && this.Validate_VariablePassword === true)
    }

    get Validate_VariableEmail(): boolean {
        return /\S+@\S+\.\S+/.test(this.FormValues.email)
    }

    get Validate_VariablePassword(): boolean {
        return /^\S{4,32}$/.test(this.FormValues.password)
    }

    //</editor-fold>
}
