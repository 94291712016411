import Router from '../../data/router'

class Url {
    public static SetUrl(newUrl: string): void {
        if (typeof newUrl !== 'string' || newUrl.length === 0) {
            newUrl = '/'
        } else if (newUrl.substr(0, 1) !== '/') {
            newUrl = '/' + newUrl
        }
        Router.push(newUrl, () => {
        })
    }

    public static ContainsUrl(name: string, step: number = -1): boolean {
        if (typeof name !== 'string' || name.length === 0 || step < -1) {
            return false
        }
        if (step === -1) {
            const fullHash: string = Router.history.current.path + (Router.history.current.path > 1 ? '/' : '')
            return fullHash.indexOf('/' + name + '/') !== -1
        }
        const splitedHash: string[] = (Router.history.current.path.length > 1 ? Router.history.current.path.substr(1).split('/') : [])
        return (splitedHash.length > step && splitedHash[step] === name)
    }
}

export default Url
