






import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import {TITLE_DEFAULT} from "@/App/data/define";

@Component
export default class UserLogoutPage extends Vue {
    public readonly name: string = 'user-logout'

    public mounted(): void {
        document.title = "Выход - "+ TITLE_DEFAULT
        console.log('user-logout - started')
        Session.OnIncomingCurrentSession(undefined)
    }
}
