







import '../asset/css/common-panel-template.less'

import {Component, Vue} from 'vue-property-decorator';

import Url from '../controller/net/url'
import PanelNavbar from "@/App/component/panel-navbar.vue";

@Component({
    components: {PanelNavbar}
})
export default class PanelTemplate extends Vue {
    public readonly name: string = 'panel-template'

    private created() {
        console.log('panel - started')
        if (Url.ContainsUrl('enther', 0) === true) {
            Url.SetUrl('/')
        }
    }
}
