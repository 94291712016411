import Vue from 'vue'

class App {
    public Instance: Vue | undefined

    public DoInit(root: Vue) {
        this.Instance = root
    }

    ForceUpdate() {
        const updateItem = (target: Vue): void => {
            for (let item of target.$children) {
                item.$forceUpdate()
                updateItem(item)
            }
        }
        if (this.Instance !== undefined) {
            updateItem(<Vue>this.Instance)
        }
    }
}

export default new App()