














































































import {Component, Vue} from 'vue-property-decorator';
import Axios from "axios";
import Session from "@/App/controller/account/session";
import Url from "@/App/controller/net/url";
import {TITLE_DEFAULT} from "@/App/data/define";
import AdmincpMenu from "@/App/component/admincp-menu.vue";

@Component({
    components: {AdmincpMenu}
})
export default class AdmincpGameEditPage extends Vue {
    public readonly name: string = 'admincp-game-edit'
    private HasDataSend: boolean = false
    private HasDataLoading: boolean = true
    private FormValues: {
        id: number,
        id_category: number,
        key: string,
        name: string,
        icon: string,
        background: string,
        updateurl: string,
        execute: string,
        buttonname: string,
        versionfile: string,
        versionhash: string,
        nowversion: string,
        htmlpage: string,
        needsteam: number,
        params: string
    } = {
        id: Number(this.$root.$route.params.id_game),
        id_category: 0,
        key: '',
        name: '',
        icon: '',
        background: '',
        updateurl: '',
        execute: '',
        buttonname: '',
        versionfile: '',
        versionhash: '',
        nowversion: '',
        htmlpage: '',
        needsteam: 0,
        params: '[]',
    }
    private ListCategory: {
        id: number,
        name: string
    }[] = []

    private created(): void {
        document.title = "Управление игрой - " + TITLE_DEFAULT
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'category.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListCategory', res.data.result)
            }
        })
        this.DoLoadData()
    }

    private DoLoadData() {
        this.HasDataLoading = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.item',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_game: this.FormValues.id
        }).then(res => {
            if (res.data.code === 0) {
                this.FormValues.id_category = res.data.result.id_category
                this.FormValues.key = res.data.result.key
                this.FormValues.name = res.data.result.name
                this.FormValues.icon = res.data.result.icon
                this.FormValues.background = res.data.result.background
                this.FormValues.updateurl = res.data.result.updateurl
                this.FormValues.execute = res.data.result.execute
                this.FormValues.buttonname = res.data.result.buttonname
                this.FormValues.versionfile = res.data.result.versionfile
                this.FormValues.versionhash = res.data.result.versionhash
                this.FormValues.nowversion = res.data.result.nowversion
                this.FormValues.htmlpage = res.data.result.htmlpage
                this.FormValues.needsteam = res.data.result.needsteam
                this.FormValues.params = res.data.result.params
            } else {
                Url.SetUrl('/admincp/game/')
            }
        }).finally(() => {
            this.HasDataLoading = false
        })
    }

    private OnSubmit(): void {
        this.HasDataSend = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.edit',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_game: this.FormValues.id,
            id_category: this.FormValues.id_category,
            key: this.FormValues.key,
            name: this.FormValues.name,
            icon: this.FormValues.icon,
            background: this.FormValues.background,
            updateurl: this.FormValues.updateurl,
            execute: this.FormValues.execute,
            buttonname: this.FormValues.buttonname,
            versionfile: this.FormValues.versionfile,
            versionhash: this.FormValues.versionhash,
            nowversion: this.FormValues.nowversion,
            htmlpage: this.FormValues.htmlpage,
            needsteam: this.FormValues.needsteam,
            params: this.FormValues.params,
        }).then(res => {

        }).finally(() => {
            this.HasDataSend = false
            Url.SetUrl('/admincp/game/')
        })
    }
}
