





















































































import {Component, Vue} from 'vue-property-decorator';
import Session from "../../../controller/account/session";
import Axios from "axios";
import AdmincpMenu from "@/App/component/admincp-menu.vue";
import {TITLE_DEFAULT} from "@/App/data/define";
import GameItemInGameList from "@/App/struct/GameItemInGameList";
import ServerItemInServerList from "@/App/struct/ServerItemInServerList";

@Component({
    components: {AdmincpMenu},
    filters: {
        formateCategoryName(value: string): string {
            return (value.substr(value.length - 1) == ':' ? value.substr(0, value.length - 1) : value)
        },
        toVipEndDate(value: string): string {
            const currentDate = new Date();
            const endDate = new Date(value)
            var diffMS = endDate.getTime() - currentDate.getTime();
            var diffS = diffMS / 1000;
            var diffM = diffS / 60;
            var diffH = diffM / 60;
            var diffD = diffH / 24;


            const diff = new Date(currentDate.getTime() - endDate.getTime())

            let finishLine: string = value
            finishLine = finishLine.substr(5)
            finishLine = finishLine.substr(0, finishLine.length - 3)
            finishLine = finishLine.substr(3, 2) + "." + finishLine.substr(0, 2) + " " + finishLine.substr(6)

            if (diffMS > 0) {
                finishLine = finishLine + " => " + ((diffD > 0 ? (Math.floor(diffD) + "д ") : ''))
            }

            return finishLine;
        }
    }
})
export default class AdmincpServerPage extends Vue {
    public readonly name: string = 'admincp-server'
    public ListServer: ServerItemInServerList[] = []

    public ListGame: Map<string, GameItemInGameList[]> = new Map<string, GameItemInGameList[]>()
    public SelectedGame?: GameItemInGameList

    public created(): void {
        document.title = "Управление серверами - " + TITLE_DEFAULT
        this.SelectedGame = undefined
        this.DoUpdateServerList()
    }

    private DoChangeVipEnd(server: ServerItemInServerList): void {
        const writeDate = prompt("Дата окончания VIP:", server.vipend);
        const vipEnd: Date = new Date(writeDate != null ? writeDate : "")
        if (isNaN(vipEnd.getDate()) === false && writeDate !== null) {
            server.vipend = writeDate
        }

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.editValues',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: server.id,
            values: {
                vipend: server.vipend
            }
        }).then(res => {
            this.$forceUpdate()
        })
    }

    private DoChangePremium(server: ServerItemInServerList): void {
        const writeDate = prompt("Наличие Premium:", String(server.premium));
        const premium: number = Number(writeDate)
        if (isNaN(premium) === false) {
            server.premium = (premium < 0 || premium > 1 ? 0 : premium)
        }

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.editValues',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: server.id,
            values: {
                premium: server.premium
            }
        }).then(res => {
            this.$forceUpdate()
        })
    }

    private DoChangeVotes(server: ServerItemInServerList): void {
        const writeDate = prompt("Колличество голосов:", String(server.vote));
        const vote: number = Number(writeDate)
        if (isNaN(vote) === false) {
            server.vote = vote
        }

        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.editValues',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: server.id,
            values: {
                vote: server.vote
            }
        }).then(res => {
            this.$forceUpdate()
        })
    }

    private HasPremiumFromServerItem(server: ServerItemInServerList): boolean {
        const currentTime: Date = new Date();
        return (Number(server.premium) === 1 && new Date(server.vipend) > currentTime)
    }

    private HasVipFromServerItem(server: ServerItemInServerList): boolean {
        const currentTime: Date = new Date();
        return (Number(server.premium) === 0 && new Date(server.vipend) > currentTime)
    }

    private GetServerList(id_game: number): ServerItemInServerList[] {
        const servers: ServerItemInServerList[] = []
        for (const server of this.ListServer) {
            if (server.id_game == id_game) {
                servers.push(server)
            }
        }
        const self = this;
        servers.sort(function (a, b) {
            const hasPremiumA: boolean = self.HasPremiumFromServerItem(a)
            const hasVipA = self.HasVipFromServerItem(a)
            const hasPremiumB: boolean = self.HasPremiumFromServerItem(b)
            const hasVipB = self.HasVipFromServerItem(b)

            if (hasPremiumA === true) {
                return -1
            }
            if (hasPremiumB === true) {
                return 1
            }
            if (hasVipA === true && hasVipB === false) {
                return -1
            }
            if (hasVipB === true && hasVipA === false) {
                return 1
            }
            if (hasVipB === true && hasVipA === true) {
                return (new Date(a.vipend) > new Date(b.vipend) ? -1 : 1)
            }
            if (Number(a.vote) > Number(b.vote)) {
                return -1
            }
            if (Number(a.vote) < Number(b.vote)) {
                return 1
            }
            return 0
        })
        return servers;
    }

    public DoSelectGame(game: GameItemInGameList | undefined): void {
        this.SelectedGame = game
        this.$forceUpdate()
    }

    public DoUpdateServerList(): void {
        this.ListGame = new Map<string, GameItemInGameList[]>();
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'game.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                let listGames: GameItemInGameList[] = res.data.result;
                for (const game of listGames) {
                    if (this.ListGame.has(game.category_name) === false) {
                        this.ListGame.set(game.category_name, [])
                    }
                    const gameListInCat = this.ListGame.get(game.category_name)
                    if (gameListInCat != undefined) {
                        gameListInCat.push(game)
                    }
                }
                this.$forceUpdate()
            }
        })

        Vue.set(this, 'ListServer', [])
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.list',
            //@ts-ignore
            session: Session.CurrentSession.SessionID
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListServer', res.data.result)
            }
        })
    }

    public DoChangeServerActive(id: number, newActive: number): void {
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'server.change.active',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: id,
            active: newActive
        }).then(res => {
            if (res.data.code === 0) {
                Vue.set(this, 'ListServer', res.data.result)
            }
        })
    }

    public DoRemoveServerItem(id: number): void {
        if (confirm('Вы действительно хотите удалить данный сервер?')) {
            Vue.set(this, 'ListServer', [])
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'server.remove',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                id_server: id
            }).then(res => {
                if (res.data.code === 0) {
                    Vue.set(this, 'ListServer', res.data.result)
                }
            })
        }
    }
}
