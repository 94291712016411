















































import {Component, Vue} from 'vue-property-decorator'
import Axios from 'axios'
import Url from '../../controller/net/url'
import {TITLE_DEFAULT} from "@/App/data/define";

@Component
export default class EntherRegPage extends Vue {
    public readonly name: string = 'registration'
    public readonly FormValues: { login: string, email: string, password: string, password2: string } = {
        login: '',
        email: '',
        password: '',
        password2: ''
    }
    public readonly FormState: { HasSendingData: boolean } = {
        HasSendingData: false
    }

    private created(): void {
        document.title = "Регистрация - " + TITLE_DEFAULT
    }

    private OnSubmit(): void {
        this.FormState.HasSendingData = true
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'enther.registration',
            email: this.FormValues.email,
            password: this.FormValues.password,
            login: this.FormValues.login
        }).then(res => {
            if (res.data.code === 0) {
                Url.SetUrl('/enther/login/' + this.FormValues.email)
            } else if (res.data.code === 1) {
                alert('Введенный логин уже кем то занят!')
            } else if (res.data.code === 2) {
                alert('Введенный Email уже кем то занят!')
            } else {
                alert('Не известная ошибка!')
            }
        }).catch(reason => {
            alert('Произошла ошибка, попробуйте еще раз или обратитесь в поддержку!')
        }).finally(() => {
            this.FormState.HasSendingData = false
        })
    }

    //<editor-fold defaultstate="collapsed" desc="Validate Class">
    get Class_VariableEmail(): string {
        if (this.FormValues.email.length > 0) {
            return ' ' + (this.Validate_VariableEmail === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    get Class_VariablePassword(): string {
        if (this.FormValues.password.length > 0) {
            return ' ' + (this.Validate_VariablePassword === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    get Class_VariablePassword2(): string {
        if (this.FormValues.password.length > 0 && this.FormValues.password2.length > 0) {
            return ' ' + (this.Validate_VariablePassword2 === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    get Class_VariableLogin(): string {
        if (this.FormValues.login.length > 0) {
            return ' ' + (this.Validate_VariableLogin === false ? 'is-invalid' : 'is-valid')
        }
        return ''
    }

    //</editor-fold>

    //<editor-fold defaultstate="collapsed" desc="Validate Properties">
    get Validate_CanSubmit(): boolean {
        return (this.FormState.HasSendingData === false && this.Validate_CanReadyForm == true)
    }

    get Validate_CanReadyForm(): boolean {
        return (this.Validate_VariableEmail === true && this.Validate_VariablePassword === true && this.Validate_VariablePassword2 === true && this.Validate_VariableLogin === true)
    }

    get Validate_VariableEmail(): boolean {
        return /\S+@\S+\.\S+/.test(this.FormValues.email)
    }

    get Validate_VariablePassword(): boolean {
        return /^[A-Za-z0-9-_ .]{4,32}$/.test(this.FormValues.password)
    }

    get Validate_VariablePassword2(): boolean {
        return this.FormValues.password == this.FormValues.password2
    }

    get Validate_VariableLogin(): boolean {
        return /^\S{6,32}$/.test(this.FormValues.login)
    }

    //</editor-fold>
}
