




























































import {Component, Vue} from 'vue-property-decorator';
import {TITLE_DEFAULT} from "@/App/data/define";
import ServerItemInServerList from "@/App/struct/ServerItemInServerList";
import Axios from "axios";
import Session from "@/App/controller/account/session";
import GameItemInGameList from "@/App/struct/GameItemInGameList";

@Component
export default class ServerEditPage extends Vue {
    public readonly name: string = 'server-edit'

    public FormValues: ServerItemInServerList | undefined = undefined
    public ServerID: number = 0;

    public created(): void {
        this.ServerID = Number(this.$root.$route.params.id)
        this.FormValues = undefined
        Axios.post('//kosiaks.alkad.org/api/index.php', {
            method: 'user.server.info',
            //@ts-ignore
            session: Session.CurrentSession.SessionID,
            id_server: this.ServerID
        }).then(res => {
            if ('code' in res.data && res.data.code == 0) {
                this.FormValues = res.data.result
                this.$forceUpdate()
            } else {
                alert("Не возможно получить данные о сервере")
                this.$root.$router.push('/user/servers')
            }
        })
    }

    private OnClickSubmit() {
        if (this.FormValues != undefined) {
            if (this.FormValues.ip.length <= 0 || this.FormValues.ip.split('.').length != 4) {
                alert("IP адрес не соотвествует требованиям")
                return
            }
            if (Number(this.FormValues.queryport) <= 100 || Number(this.FormValues.queryport) >= 65535) {
                alert("Port не соотвествует требованиям")
                return
            }
            Axios.post('//kosiaks.alkad.org/api/index.php', {
                method: 'user.server.change',
                //@ts-ignore
                session: Session.CurrentSession.SessionID,
                values: {
                    id: this.FormValues.id,
                    ip: this.FormValues.id,
                    port: this.FormValues.queryport,
                    vk: this.FormValues.web,
                    shop: this.FormValues.shop,
                }
            }).then(res => {
                if ('code' in res.data && res.data.code == 0) {
                    this.$root.$router.push('/user/servers')
                } else {
                    alert("Не возможно прикрепить сервер")
                }
            })
        }
    }

    private destroyed(): void {

    }

    public mounted(): void {
        document.title = "Прикрепление сервера - " + TITLE_DEFAULT
        console.log('server-create - started')
    }
}
